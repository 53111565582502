@media (max-width: 991.98px) {
	
    .content .content-column .menu .menu-icon{
        display: block;
    }
	.content .search-column {
        position: absolute;
        left: 1.5rem;
        top: 7rem;
        width: 28.5rem;
        margin: 0px;
        flex:none;
        background-color: #fff;
        border: 2px solid #310d67;
        display: none;
    }
	.content .search-column.show {
        display: block;
    }
}