html,body {
	font-size:10px;
	color:#333333;
	background-color:#fff;
	font-family: 'PT Sans', sans-serif;
	font-weight:400;
}
body.ReactModal__Body--open{
	overflow:hidden;
}
:focus {
    outline: none;
}
a,
a:hover{
	color:#c60b26;
	text-decoration: underline;
}
hr{
	border-top:4px solid rgba(0,0,0,.1);
}
button:focus,
a:focus {
    outline:0px none;
}

.content{
	margin-top: 20px;
	margin-bottom: 10rem;
	
}
/************** Levý sloupec ************/
.content .search-column{
	flex: 0 0 28.5rem;
}

.content .search-column .search-input{
	background-color: #f3f3f3;
	
}
.content .search-column .search-input{
	background-color: #f3f3f3;
	height: 4.2rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.content .search-column .search-input input,
.content .search-column .search-input input:focus{
	background-color: transparent;
	border-radius: 0px;
	display: flex;
	height: 100%;
	border:none;
	font-size: 1.5rem;
	color:#000;
	box-shadow:none;
}
.content .search-column .search-input img.search-icon{
	width: 1.7rem;
	height: 1.7rem;
	margin: 10px;
	color: #310d67;
}
.content .search-column .category-list {
	margin-top: 2rem;
	display: flex;
}
.content .search-column .category-list ul{
	list-style: none;
	padding: 0px;
	margin:0px;
	width: 100%;
}
.content .search-column .category-list ul li{
	list-style: none;
	background-color: #f3f3f3;
}
.content .search-column .category-list ul li .item-row{
	border-bottom: 1px solid #fff;
	padding: 1.5rem;
	font-size: 1.5rem;
	color: #000;
	font-weight: 700;
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 4.2rem;
}
.content .search-column .category-list ul li .item-row:hover,
.content .search-column .category-list ul li .item-row.selected{
	background-color: #c60b26;
	color: #fff;
}
.content .search-column .category-list ul li .item-row .icon{
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	color:#fff;
	border-radius: 4px;
	margin-right: 1.2rem;
}
.content .search-column .category-list ul li .item-row .icon img{
	width: 1rem;
	height: 1rem;
	align-self: center;
}
.content .search-column .category-list ul li .item-row .icon.open{
	background-color: #310d67;
	
}
.content .search-column .category-list ul li .item-row .icon.closed{
	background-color: #c60b26;	
}
.content .search-column .category-list ul li.closed ul{
	display: none;
}


/************** Střední sloupec ************/

.content .content-column{
	display: flex;
	flex-grow: 1;
	padding:0 1.5rem;
	justify-content: flex-start;	
	flex-direction: column;
}

.content .content-column .menu{
	display: flex;
	align-items: center;
}
.content .content-column .menu .menu-icon{
	display: none;
}
.content .content-column .menu .info-icon{
	display: none;
}
.content .content-column .menu .menu-icon img{
	width: 3rem;
	height:3rem;
	margin-right: 2rem;
	cursor: pointer;
}
.content .content-column .menu .info-icon img{
	width: 3rem;
	height:3rem;
	margin-right: 2rem;
	cursor: pointer;
}

.content .content-column .currency-switcher .item{
	cursor: pointer;
    padding: 0.4rem 1.2rem;
	user-select: none;
}
.content .content-column .currency-switcher .item.selected{
	background-color: #c60b26;
    color: #ffffff;
}

.content .content-column .brad-crumb-menu{
	font-size: 1.5rem;
	color: #000;
	display: flex;
	align-items: center;
	height: 4.2rem;
	flex-grow: 1;
}
.content .content-column .brad-crumb-menu .title{
	margin-right: 10px;
}

.content .content-column .brad-crumb-menu .separator{
	color:rgba(0, 0, 0, 0.5);
	font-weight: 700;
}
.content .content-column .brad-crumb-menu .menu-item{
	margin: 0rem 0.5rem;
	color: #c60b26;
	font-weight: 700;
	cursor: pointer;
}
.content .content-column .brad-crumb-menu .menu-item.last{
	margin: 0rem 0.5rem;
	color: #000;
	cursor: default;
}
.content .content-column .products{
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	
	flex: 0 0;
}
.content .content-column .products .header {
	display: flex;
	flex-direction: row;
}
.content .content-column .products .header .item {
	background-color: #c60b26;
	height: 4.2rem;
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
	border-left: 2px solid #fff;
	padding-right: 2rem;
	padding-left: 2rem;
	display: flex;
	align-items: center;
}
.content .content-column .products .header .item:first-child{
	border-left: none;
}
.content .content-column .products .header .item  .cont{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	cursor: pointer;
}
.content .content-column .products .header .item.code,
.content .content-column .products .body .one-row .code{
	flex: 0 0 17rem;
}
.content .content-column .products .header .item.price,
.content .content-column .products .body .one-row .price{
	flex: 0 0 15rem;
}

.content .content-column .products .header .item.name,
.content .content-column .products .body .one-row .name{
	flex-grow: 1;
}
.content .content-column .products .body .one-row{
	display: flex;
	flex-direction: row;
}
.content .content-column .products .body .one-row:nth-child(odd){
	background-color: #f3f3f3;
}
.content .content-column .products .body .one-row:nth-child(even){
	background-color: #e3e3e3;
}
.content .content-column .products .body .one-row .item{
	padding: 1rem 2rem;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	min-height: 4.2rem;
}
.content .content-column .products .body .one-row .item.price{
	border-left: 2px solid #fff;
}
.content .content-column .products .body .one-row .item.name{
	display: flex;
	flex-direction: row;
	border-left: 2px solid #fff;
}
.content .content-column .products .body .one-row .item.name .text{
	margin-right: 1.5rem;
	flex-grow: 1;
}
.content .content-column .products .body .one-row .item.name .form{
	display: block;
	align-items: center;
	flex-direction: row;
}
.content .content-column .products .body .one-row .item.name .amount-label{
	margin-right: 10px;
	font-weight: 700;
	color: #310d67;
}
.content .content-column .products .body .one-row .item.name .amount-input{
	margin-right: 0.2rem;
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	width: 4rem;
	height: 3rem;
	text-align: center;
	font-size: 1.5rem;
	color: #310d67;
	box-shadow:none;
}
.content .content-column .products .body .one-row .item.name .unit-select{
	margin-right: 0.2rem;
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	width: 4rem;
	height: 3rem;
	text-align: center;
	font-size: 1.5rem;
	padding: 0;
	color: #310d67;	
	box-shadow:none;
}
.content .content-column .products .body .one-row .item.name .confirm-button{
	font-weight: 700;
	border-radius: 0px;
	width: 4rem;
	height: 3rem;
	text-align: center;
	font-size: 1.5rem;
	padding: 0;
	color: #fff;
	border:none;
	background-color: #c60b26;
}
.content .content-column .products .body .one-row .item.name .inquiry-button{
	margin-right: 0.2rem;
	font-weight: 700;
	border-radius: 0px;
	width: 12.4rem;
	height: 3rem;
	text-align: center;
	font-size: 1.5rem;
	padding: 0;
	color: #fff;
	border:none;
	background-color: #310d67;
}
.content .content-column .products .body .one-row .item.name .inquiry-button.selected{
	background-color: #c60b26;
}
.content .content-column .paging{
	display: flex;
	flex-direction: row;
	padding:2rem 0rem;
    flex-wrap: wrap;
}
.content .content-column .paging .on-page-block{
	font-size: 1.5rem;
	color:#000;
	flex: 0 0 17.3rem;
}
.content .content-column .paging .on-page-block select{
	margin-right: 0.2rem;
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	width: 5.4rem;
	height: 4.2rem;
	text-align: center;
	font-size: 1.5rem;
	padding: 0;
	color: #310d67;
	margin-left: 1rem;
	box-shadow:none;
}
.content .content-column .paging .results-shown-count{
	font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0px;
    height: 4.2rem;
}
.content .content-column .order-block{
	background-color: #f3f3f3;
	padding: 2rem;
	width: 100%;
}
.content .content-column .order-block .order-title{
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 2rem;
}
.content .content-column .order-block .thank-you{
	font-size: 2.4rem;
	background-color: #c60b26;
	color: #fff;
	padding: 4rem 2rem;
	text-align: center;
	font-weight: 700;
}
.content .content-column .order-block .selected-products .header{
	display: flex;
}
.content .content-column .order-block .selected-products .header .item{
	display: flex;
	font-size: 1.5rem;
	font-weight: 700;
	height: 4.2rem;
	align-items: center;
}
.content .content-column .order-block .selected-products .header .item .required,
.content label .required{
	color:#c60b26;
	margin-left: 0.3rem;
}
.content .content-column .order-block .selected-products .header .item.product{
	flex-grow: 1;
}
.content .content-column .order-block .selected-products .header .item.amount{
	flex: 0 0 11rem;
	padding-left: 0px;
	padding-right: 2rem;
}
.content .content-column .order-block .selected-products .header .item.unit{
	flex: 0 0 8rem;
}
.content .content-column .order-block .selected-products .header .item.price{
	flex: 0 0 8rem;
}
.content .content-column .order-block .selected-products .header .item.remove{	
	flex: 0 0 3.2rem;
}

.content .content-column .order-block .selected-products .body{
	display: flex;
	flex-direction: column;
}
.content .content-column .order-block .selected-products .body .no-products-inserted{
	text-align: center;
	padding: 2rem;
	font-size: 1.5rem;
}
.content .content-column .order-block .selected-products .body .one-row{
	margin-bottom: 0.2rem;
	display: flex;

}
.content .content-column .order-block .selected-products .body .item{
	display: flex;
	font-size: 1.5rem;
	font-weight: 700;
	min-height: 4.2rem;
	align-items: center;
	padding: 1rem 2rem;
}
.content .content-column .order-block .selected-products .body .item.code{
	flex: 0 0 13rem;
	background-color: #c60b26;
	margin-right: 0.3rem;
	color: #fff;
	justify-content: center;
}
.content .content-column .order-block .selected-products .body .form-inputs{
	display: flex;
	flex-direction: row;
	flex: 0 0 22rem;
}
.content .content-column .order-block .selected-products .body .form-inputs.inputs-with-price{
	flex: 0 0 30rem;
}

.content .content-column .order-block .selected-products .body .delivery-price-label{
	width: 24rem;
    padding: 2rem 1rem;
    font-size: 1.6rem;
    font-weight: bold;
}
.content .content-column .order-block .selected-products .body .delivery-price-amount{
	width: 12rem;
    padding: 2rem 1rem;
    font-size: 1.6rem;
    font-weight: bold;
}
.content .content-column .order-block .selected-products .body .total-price-label{
    padding: 2rem 1rem;
    font-size: 1.8rem;
    font-weight: bold;
}
.content .content-column .order-block .selected-products .body .total-price-label .info{
    font-size: 1.2rem;
	font-weight: normal;
}
.content .content-column .order-block .selected-products .body .total-price-amount{
	width: 12rem;
    padding: 2rem 1rem;
    font-size: 1.8rem;
    font-weight: bold;
	padding-right: 0px;
}
.content .content-column .order-block .selected-products .body .handling-price-label{
    padding: 2rem 1rem;
    font-size: 1.6rem;
    font-weight: bold;
}
.content .content-column .order-block .selected-products .body .handling-price-label .info{
    font-size: 1.2rem;
	font-weight: normal;
}
.content .content-column .order-block .selected-products .body .handling-price-amount{
	width: 12rem;
    padding: 2rem 1rem;
    font-size: 1.6rem;
    font-weight: bold;
	padding-right: 0px;
}

.content .content-column .order-block .selected-products .body .item.name{
	flex-grow: 1;
	background-color: #c60b26;
	color: #fff;
	margin-right: 2rem;
}
.content .content-column .order-block .selected-products .body .item.amount{
	flex: 0 0 11rem;
	padding-left: 0px;
	padding-right: 2rem;
}
.content .content-column .order-block .selected-products .body .item.amount input{		
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	width: 100%;
	height: 4.2rem;
	text-align: center;
	font-size: 1.5rem;
	color: #310d67;
	box-shadow:none;
}
.content .content-column .order-block .selected-products .body .item.unit{
	flex: 0 0 8rem;
	padding: 0px;
}
.content .content-column .order-block .selected-products .body .item.unit select{
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	width: 5.4rem;
	height: 4.2rem;
	text-align: center;
	font-size: 1.5rem;
	padding: 0;
	color: #310d67;
}
.content .content-column .order-block .selected-products .body .item.price{
	flex: 0 0 8rem;
	padding: 0px;
	font-size: 1.3rem;
}
.content .content-column .order-block .selected-products .body .item.remove{	
	flex: 0 0 3.2rem;
	padding: 0px;
	justify-content: flex-end;
}
.content .content-column .order-block .selected-products .body .item.remove img{
	width: 1.8rem;
	height: 1.8rem;
	cursor: pointer;
}
.content .content-column .order-block .order-form{
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
}
.content .content-column .order-block .order-form .form-group{
	margin-bottom: 3rem;
}
.content label{
	font-size: 1.5rem;
	font-weight: 700;
	display: flex;
}
.content  input.form-control{
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	height: 4.2rem;
	font-size: 1.5rem;
	padding: 0 1rem;
	color: #000;
	box-shadow:none;
}

.content .content-column .order-block .order-form textarea{
	font-weight: 700;
	border: 2px solid #310d67;
	border-radius: 0px;
	font-size: 1.5rem;
	padding: 0;
	color: #000;
	height: 8rem;
	padding: 1rem;
}
.content .content-column .order-block .order-form .terms{
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	font-weight: 700;
	cursor: default;
}
.content .content-column .order-block .order-form .terms .checkbox{
	width: 3rem;
	height: 3rem;
	border: 2px solid #310d67;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2rem;
	cursor: pointer;
	flex: 0 0 3rem;
}
.content .content-column .order-block .order-form .terms .checkbox img{
	width: 2rem;
	height: 2rem;
	color:#000;
}
.content .content-column .order-block .order-form button.send-inquiry{
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
	background-color: #c60b26;
	min-width: 20rem;
	border: none;
	align-self: flex-end;
	margin-top: 3rem;
	height: 4.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.content .send-loading{
	height: 3rem;
}
.content .content-column .order-block .order-form button.send-inquiry:hover,
.btn:hover{
	background-color: #310d67;
}
.content .content-column .order-block .order-form button.send-inquiry.loading:hover,
.btn.loading:hover{
	background-color: #c60b26;
	cursor: default;
}

/************** Pravý sloupec ************/
.content .help-column{
	margin-top: 10rem;
	flex: 0 0 28.8rem;
	position: relative;
}
.content .help-column .help-block{
	background-color: #f3f3f3;
	padding: 1.5rem 2rem;
	position: absolute;	
}
.content .help-column .help-block .title{
	font-weight: 700;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}
.content .help-column .help-block .text{
	font-size: 1.5rem;
	line-height: 2.5rem;
}

/************** Account blok ************/
.account-block{
	position: absolute;
	top: 3rem;
	right: 2rem;
}
.account-block .login{
	font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
	text-decoration: underline;
	color: #c60b26;
	cursor: pointer;
}
.account-block .separator{
	border-right: 2px solid #310d67;
    margin: 0px 10px;
    height: 1.6rem;
}



/************** Login page ************/
.login .input-group-text img{
	height: 1.5rem;
}
.login .login-card{
	background-color: #f3f3f3 !important;
}


/************** Obecné nastavení ************/

.btn{
	color: #fff !important;
	font-size: 1.5rem;
	font-weight: 700;
	background-color: #c60b26;
	border: none;
	align-self: flex-end;
	height: 4.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0;
}
.btn.btn-grey{
	color: #000 !important;
	font-size: 1.5rem;
	font-weight: 700;
	background-color: #bbb;
	border: none;
	align-self: flex-end;
	height: 4.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0;
}

.notifications-component{
	top:0;
}

.triangle-to-top{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 7px 6px;
    border-color: transparent transparent #fff transparent;
    margin-bottom:2px;
}
.triangle-to-top.selected{
    border-color:  transparent transparent #000 transparent;
}
.triangle-to-bottom{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 6px 0 6px;
    border-color: #fff transparent transparent transparent;
}
.triangle-to-bottom.selected{
    border-color: #000 transparent transparent transparent;
}

.pagination {
	margin:0px;
}
.pagination li{
	margin-right: 0.2rem;
}
.pagination li a{
	background-color: #f3f3f3;
	font-size: 1.5rem;
	color: #000;
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	text-decoration: none;
}
.pagination li a.selected,
.pagination li a:hover{
	background-color: #c60b26;
	color:#fff;
	text-decoration: none;
}

.cursor-pointer{
	cursor:pointer;
}
.react-datepicker{
	font-size: 1.8rem !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 3rem !important;
    line-height: 2.8rem !important;
    margin: 0.2rem !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-size: 1.944rem !important;
}
.datepicker-w100 .react-datepicker-wrapper{
	width: 100%;
}
.notification-wrapper{
	position: absolute !important;
	width: 100%;
	height: 40rem;
	left: 0px;
	pointer-events: none;
}
.notifications-component{
	position: absolute !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.whole-loading{
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	flex-grow: 1;
}
.loading .text{
	font-size: 1.5rem;
	font-weight: 700;
}
.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #c60b26;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4);}  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

@media (min-width: 576px){
	.modal-dialog {
		max-width: 500px;
		margin: 0rem auto;
		padding: 1.75rem 0rem;
	}
}

.hp-1{
	height: 1px !important;
}
.hp-2{
	height: 2px !important;
}
.hp-3{
	height: 3px !important;
}
.hp-4{
	height: 4px !important;
}
.hp-5{
	height: 5px !important;
}
.hp-6{
	height: 6px !important;
}
.hp-7{
	height: 7px !important;
}
.hp-8{
	height: 8px !important;
}
.hp-9{
	height: 9px !important;
}
.hp-10{
	height: 10px !important;
}
.hp-11{
	height: 11px !important;
}
.hp-12{
	height: 12px !important;
}
.hp-13{
	height: 13px !important;
}
.hp-14{
	height: 14px !important;
}
.hp-15{
	height: 15px !important;
}
.hp-16{
	height: 16px !important;
}
.hp-17{
	height: 17px !important;
}
.hp-18{
	height: 18px !important;
}
.hp-19{
	height: 19px !important;
}
.hp-20{
	height: 20px !important;
}
.hp-21{
	height: 21px !important;
}
.hp-22{
	height: 22px !important;
}
.hp-23{
	height: 23px !important;
}
.hp-24{
	height: 24px !important;
}
.hp-25{
	height: 25px !important;
}
.hp-26{
	height: 26px !important;
}
.hp-27{
	height: 27px !important;
}
.hp-28{
	height: 28px !important;
}
.hp-29{
	height: 29px !important;
}
.hp-30{
	height: 30px !important;
}
.hp-31{
	height: 31px !important;
}
.hp-32{
	height: 32px !important;
}
.hp-33{
	height: 33px !important;
}
.hp-34{
	height: 34px !important;
}
.hp-35{
	height: 35px !important;
}
.hp-36{
	height: 36px !important;
}
.hp-37{
	height: 37px !important;
}
.hp-38{
	height: 38px !important;
}
.hp-39{
	height: 39px !important;
}
.hp-40{
	height: 40px !important;
}
.hp-41{
	height: 41px !important;
}
.hp-42{
	height: 42px !important;
}
.hp-43{
	height: 43px !important;
}
.hp-44{
	height: 44px !important;
}
.hp-45{
	height: 45px !important;
}
.hp-46{
	height: 46px !important;
}
.hp-47{
	height: 47px !important;
}
.hp-48{
	height: 48px !important;
}
.hp-49{
	height: 49px !important;
}
.hp-50{
	height: 50px !important;
}

.fs1{
	font-size: 1px !important;
}
.fs2{
	font-size: 2px !important;
}
.fs3{
	font-size: 3px !important;
}
.fs4{
	font-size: 4px !important;
}
.fs5{
	font-size: 5px !important;
}
.fs6{
	font-size: 6px !important;
}
.fs7{
	font-size: 7px !important;
}
.fs8{
	font-size: 8px !important;
}
.fs9{
	font-size: 9px !important;
}
.fs10{
	font-size: 10px !important;
}
.fs11{
	font-size: 11px !important;
}
.fs12{
	font-size: 12px !important;
}
.fs13{
	font-size: 13px !important;
}
.fs14{
	font-size: 14px !important;
}
.fs15{
	font-size: 15px !important;
}
.fs16{
	font-size: 16px !important;
}
.fs17{
	font-size: 17px !important;
}
.fs18{
	font-size: 18px !important;
}
.fs19{
	font-size: 19px !important;
}
.fs20{
	font-size: 20px !important;
}
.fs21{
	font-size: 21px !important;
}
.fs22{
	font-size: 22px !important;
}
.fs23{
	font-size: 23px !important;
}
.fs24{
	font-size: 24px !important;
}
.fs25{
	font-size: 25px !important;
}
.fs26{
	font-size: 26px !important;
}
.fs27{
	font-size: 27px !important;
}
.fs28{
	font-size: 28px !important;
}
.fs29{
	font-size: 29px !important;
}
.fs30{
	font-size: 30px !important;
}
.fs35{
	font-size: 35px !important;
}
.fs40{
	font-size: 40px !important;
}
.fs45{
	font-size: 45px !important;
}
.fs50{
	font-size: 50px !important;
}

