@media (max-width: 1199.98px) {
    .content .help-column{
        margin-top: 2rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .content .help-column .help-block{
        top:0px !important;
    }

}