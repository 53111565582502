@media (max-width: 767.98px) {
	.content .content-column .paging {
        flex-direction: column;
        align-items: center;
    }
	.content .content-column .paging .on-page-block {
        flex: none;
        margin-bottom: 2rem;
    }
    .content .content-column .paging .results-shown-count {
        flex: none;
        margin-top: 2rem;
    }
    .content .content-column .products .body .one-row .item.name {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .content .content-column .products .body .one-row .item.name .form {
        margin-top: 1rem;
    }
    .content .content-column .order-block .selected-products .body .one-row {
        flex-direction: column;
    }
    .content .content-column .order-block .selected-products .header .item.amount,
    .content .content-column .order-block .selected-products .header .item.unit,
    .content .content-column .order-block .selected-products .header .item.price{
        display: none;
    }
    .content .content-column .order-block .selected-products .body .item.code {
        flex: none;
        background-color: #c60b26;
        margin-right: 0rem;
        color: #fff;
        justify-content: flex-start;
        height: auto;
        padding-bottom: 0px;
        min-height: auto;
    }
    .content .content-column .order-block .selected-products .body .item.name {
        flex-grow: initial;
        background-color: #c60b26;
        color: #fff;
        margin-right: 0rem;
        padding-top: 0rem;
        min-height: auto;
    }
    .content .content-column .order-block .selected-products .body .form-inputs {
        display: flex;
        flex-direction: row;
        flex: none;
        margin-bottom: 2rem;
    }
    .content .content-column .order-block .selected-products .body .form-inputs.inputs-with-price {
        flex: none;
    }
}
	