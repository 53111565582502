@media (max-width: 575.98px) {
	.content .content-column {
        padding: 0rem;
    }
    .content .content-column .brad-crumb-menu .menu-item{
        display: none;
    }
    .content .content-column .brad-crumb-menu .menu-item.last{
        display: block;
    }
    .content .content-column .brad-crumb-menu .separator{
        display: none;
    }
    .content .help-column{
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .content .help-column .help-block{
        top:0px !important;
    }
    
}
	